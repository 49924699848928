import React, { useEffect, useState } from 'react';
import configData from "../config.json";
import ConfirmationModal from '../components/ConfirmationModal';

function DisplayPDF() {
    const [showModal, setShowModal] = useState(false);

    // Function to check if the device is iOS
    const isIOSDevice = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    // Function to check if the browser is Safari
    const isSafari = () => {
        const ua = navigator.userAgent;
        return /Safari/.test(ua) && !/Chrome/.test(ua);
    };

    useEffect(() => {
        // Redirect if on an iOS device or using Safari browser
        if (isIOSDevice() || isSafari()) {
            window.location.href = `${configData.APP_URL}/PDFFiles/${window.location.href.split('/').slice(-2).join('/')}`;
            return; // Stop further execution in case of redirection
        }

        // Cookie logic to show modal
        const getCookie = (name) => {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + '=')) {
                    return cookie.substring(name.length + 1);
                }
            }
            return null;
        };

        const myCookieValue = getCookie('confirmationModalShown');
        setShowModal(!myCookieValue);

        // Resize iframe logic
        const resizeIframe = () => {
            const iframe = document.querySelector('.pdfIframe');
            if (iframe) {
                iframe.style.height = window.innerHeight + 'px';
            }
        };

        resizeIframe(); // Initial resize

        const handleResize = () => {
            resizeIframe();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
        };
    }, []); // Only run this effect once when the component mounts

    const handleYesClick = () => {
        setShowModal(false);
    };

    const pdfUrl = `${configData.APP_URL}/PDFFiles/${window.location.href.split('/').slice(-2).join('/')}`;

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {!isIOSDevice() && !isSafari() && (
                <iframe
                    src={pdfUrl}
                    className="pdfIframe"
                    style={{ height: '100%', width: '100%', border: 'none' }}
                    title="PDF Viewer"
                />
            )}

            {/* Confirmation modal */}
            {showModal && (
                <ConfirmationModal
                    show={showModal}
                    data-keyboard={false}
                    handleClose={() => setShowModal(false)}
                    handleYesClick={handleYesClick}
                />
            )}
        </div>
    );
}

export default DisplayPDF;
