import React, { useState, useEffect, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../css/App.css";
import "../css/core.css";
import Header from "components/Header/header";
import Footer from "components/Footer/Footer";
import '../css/colors.css'

import { LdsSearch } from '@elilillyco/ux-lds-react';
 
function Content() {
  const [Data, setData] = useState([]);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFileurl, setSearchFileurl] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('latest');
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
 
  useEffect(() => {
    fetch("../../../metadata/metadata.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      myJson.sort(custom_sort).reverse();
      const filteredData = myJson.filter((l) => l.status.match("Published"));
      setData(filteredData);
    });
  }, []);
 
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    for (const param of params) {
      if (param[0] === "category") {
        setCurrentCategory(param[1]);
      } else if (param[0] === "year") {
        setCurrentYear(parseInt(param[1]));
        setCurrentCategory(param[0]);
      }
    }
  }, [window.location.search]);

  const getTitle = () => {

    switch (currentCategory) {

      case 'latest':
        return '最新';
      
      case 'corporate':
        return '企業情報';

      case 'product':
        return '製品・疾患';

      case 'socialimpact':
        return 'ソーシャルインパクト';

      case 'year':
        return currentYear+'年度';

      default:
        return '最新';
    }

  }
 
  const searchCheck = (searchValue) => {
    
    const newFiler = Data.filter((value) => {
      return value.Title.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchFileurl(newFiler);
    setSearchTerm(searchValue);

  }
 
  const submitSearch = (checkvalue) => {
    let results = null;
    for (let SearchData of searchFileurl){
      if (SearchData.Title === checkvalue){
        results = SearchData;
        break;
      }
        
    }
    
    if(results) {
      window.open('previewPDF/'+results.Year+'/'+results.FileName, '_blank');
      setTimeout(() => {
        setSearchTerm('');
        setSearchFileurl([]);
      }, 100);
    }
  };

  const filteredSuggestions = searchTerm.length >= 2 ? Data.filter((searchvalue) => 
    searchvalue.Title.toLowerCase().includes(searchTerm.toLowerCase())).map(searchvalue => searchvalue.Title) : [];

  
 
  function custom_sort(a, b) {
    return new Date(a.spt) - new Date(b.spt);
  }
 
  const yearlist = [];
  let thisYear = new Date().getFullYear();
  for (var i = 1; i < 7; i++) {
    yearlist.push(thisYear - i);
  }
 
  return (
    <Fragment>
      <Header />
      <div className="page-container">
        <div className="container page-content">
          <div>
            <div className="row mt48 parent" >
              <div className="col-sm-6">
                <h2 id="mainheading">
                <br />
                   プレスリリース
                  <br />
                  {/* ニュース 報道関係者向け */}
                  <h5 id="headingtitle"> {getTitle() } 報道関係者向け</h5>
                  
                </h2>
              </div>
              <div className="col-sm-6">
                <br />
                <LdsSearch
                  id={'searchAutoSuggest'}
                  inputAriaLabel={null}
                  isTypeAhead={true}
                  onChange={searchCheck}
                  onSubmit={submitSearch}
                  searchSuggestions={filteredSuggestions}
                  value={searchTerm}
                className = "search-submitbg-color"
                placeholder = "キーワード検索 (２文字以上)"

              />
                  
              </div>
            </div>
            
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12 order-lg-1-sm">
                <div className="p-2 col-lg-11 col-md-11 col-sm-12">
                  <div>
                    {Data.map((post, index) => {
                      let date = post.Release.split("-");
                      const categories = Array.isArray(post.category)? post.category.join(',').split(',').map(cat => cat.trim()) : post.category.split(',').map(cat => cat.trim());
                     
                      const showPost = 
                        (currentCategory === 'latest' && parseInt(post.Year) === thisYear) ||
                        (currentCategory === 'corporate' && (categories.includes("業績発表") || categories.includes("企業情報"))) ||
                        (currentCategory === 'product' && categories.includes("製品・疾患")) ||
                        (currentCategory === 'socialimpact' && categories.includes("ソーシャルインパクト")) ||
                        (currentCategory === 'year' && parseInt(post.Year) === currentYear);
 
                      if (showPost) {
                        return (
                          <div key={index}>
                            <h3> {date[0]}年{date[1]}月{date[2]}日 </h3>
                            <p>
                              <a
                                className="pdf latest-story"
                                href={"/jp/previewPDF/" + post.Year + "/" + post.FileName}
                                target="_blank"
                                rel="noopener noreferrer"
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "anywhere",
                                        }}
                              >
                                {post.Title}{" "}
                              </a>
                            </p>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 order-lg-2-sm d-md-block" id="year_list">
                <h6>カテゴリー</h6>
                <ul className="side-navigation p-2">
                  <li key={0}><a className={currentCategory === 'latest' ? "active" : ""} href="/jp">最新</a></li>
                  <li><a className={currentCategory === 'corporate' ? "active" : ""} href="?category=corporate">企業情報</a></li>
                  <li><a className={currentCategory === 'product' ? "active" : ""} href="?category=product">製品・疾患</a></li>
                  <li><a className={currentCategory === 'socialimpact' ? "active" : ""} href="?category=socialimpact">ソーシャルインパクト</a></li>
                </ul>
                <h6>バックナンバー</h6>
                <ul className="side-navigation p-2">
                  {yearlist.map((comp, index) => (
                    <li key={index + 1}><a className={currentYear === comp ? "active" : ""} href={"?year=" + comp}>{comp}年度</a></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
 
export default Content;
